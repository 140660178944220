<template>
  <div class="container mx-auto px-4 py-8">
    <h1 class="text-2xl font-bold mb-6">Syarat dan Ketentuan Penggunaan</h1>

    <div class="space-y-6">
      <section>
        <h2 class="text-xl font-semibold mb-3">1. Ketentuan Umum</h2>
        <p>Dengan mengakses dan menggunakan Gunsa App, Anda menyetujui untuk terikat dengan syarat dan ketentuan penggunaan ini. Portal ini disediakan oleh Gereja Gunung Sahari untuk kepentingan jemaat dan pengunjung.</p>
      </section>

      <section>
        <h2 class="text-xl font-semibold mb-3">2. Penggunaan Konten</h2>
        <p>Seluruh konten yang tersedia di Gunsa app ini, termasuk namun tidak terbatas pada teks, gambar, audio, video, dan materi ibadah, dilindungi oleh hak cipta. Konten hanya boleh digunakan untuk kepentingan pribadi dan non-komersial.</p>
      </section>

      <section>
        <h2 class="text-xl font-semibold mb-3">3. Kebijakan Privasi</h2>
        <p>Kami menghormati privasi pengguna Gunsa app. Data pribadi yang dikumpulkan hanya akan digunakan untuk kepentingan pelayanan gereja dan tidak akan dibagikan kepada pihak ketiga tanpa persetujuan.</p>
      </section>

      <section>
        <h2 class="text-xl font-semibold mb-3">4. Batasan Tanggung Jawab</h2>
        <p>Gunsa app tidak bertanggung jawab atas kerugian yang mungkin timbul dari penggunaan atau ketidakmampuan menggunakan portal ini. Kami berusaha menjaga keakuratan informasi, namun tidak menjamin bahwa informasi selalu lengkap dan terkini.</p>
      </section>

      <section>
        <h2 class="text-xl font-semibold mb-3">5. Ketentuan Persembahan Online</h2>
        <p>Fitur persembahan online disediakan untuk memudahkan jemaat. Gunsa app tidak bertanggung jawab atas kesalahan transfer yang disebabkan oleh kesalahan pengguna.</p>
      </section>

      <section>
        <h2 class="text-xl font-semibold mb-3">6. Perubahan Ketentuan</h2>
        <p>Gunsa app berhak untuk mengubah syarat dan ketentuan ini sewaktu-waktu tanpa pemberitahuan sebelumnya. Perubahan akan berlaku segera setelah dipublikasikan di portal.</p>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TermOfUse'
}
</script>
